import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';
import { AppComponent } from './app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app.routing';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutContainersModule } from 'src/app/containers/layout/layout.containers.module';
import { SettingsComponent } from './settings/settings.component';
import {NouisliderModule} from "ng2-nouislider";
import { HttpClientModule } from '@angular/common/http';
import { FormsContainersModule } from 'src/app/containers/forms/forms.containers.module';
import {ReactiveFormsModule} from "@angular/forms";
import { ComponentsCarouselModule } from 'src/app/components/carousel/components.carousel.module';
import { BootstrapModule } from 'src/app/components/bootstrap/bootstrap.module';
import { VisualisationsComponent } from './visualisations/visualisations.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableResponsiveModule } from './ui/mat-table-responsive/mat-table-responsive.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AppComponent, SettingsComponent,VisualisationsComponent],
  imports: [
    FormsContainersModule,
    CommonModule,
    AppRoutingModule,
    SharedModule,
    LayoutContainersModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NouisliderModule,
    HttpClientModule,
    ComponentsCarouselModule,
    BootstrapModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableResponsiveModule,
    MatProgressSpinnerModule,
  ]
})
export class AppModule { }

